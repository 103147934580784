import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import type { TFunction } from "i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import Accordion from "../components/Accordion";
import VerticalAccordion from "../components/VerticalAccordion";
import { getIsVertical } from "../utils/getIsVertical";
import { COLORS } from "../utils/consts";

import "../styles/kas-ir-rure.css";

import pilotprojekts41 from "../files/pilotprojekts_41_vienotas_klientu_veselibas.pdf";
import CFLA from "../files/Info_majas_lapai_CFLA.pdf";

export default function WhatIsRure() {
	const { t } = useTranslation();
	const isVertical = getIsVertical();

	return (
		<Layout pageId={PageId.WhatIsRure}>
			<Seo
				title={t("seoTitle")}
				description={t("topText")}
			/>
			<div className="contentBlock topTextSection">
				<h1>{t("topText")}</h1>
			</div>
			<div className="contentBlock noPadding">
				<StaticImage
					src="../images/kas-ir-rure/1.png"
					quality={95}
					formats={["auto", "webp", "avif"]}
					alt="Rūre komanda"
					placeholder="blurred"
					loading="eager"
				/>
			</div>
			<div className="contentBlock">
				<table className="newsTable">
					<tbody>
						<tr>
							<td>
								<div className="newsTableItem">
									<a href={pilotprojekts41} target="_blank">
										<div className="newsTableImage">
											<StaticImage
												src="../images/kas-ir-rure/pilotprojekts41.png"
												quality={95}
												formats={["auto", "webp", "avif"]}
												alt="pilotprojekts41"
												placeholder="blurred"
											/>
										</div>
										<p className="newsTableTitle">{t("pilotprojekts41")}</p>
									</a>
								</div>
							</td>
							<td colSpan={2}>
								<div className="newsTableItem">
									<a href={CFLA} target="_blank">
										<div className="newsTableImage newsTableImage2">
											<StaticImage
												src="../images/kas-ir-rure/CFLA.png"
												quality={95}
												formats={["auto", "webp", "avif"]}
												alt="CFLA"
												placeholder="blurred"
											/>
										</div>
										<p className="newsTableTitle">{t("CFLA")}</p>
										<ol className="newsTableList">
											<li>{t("CFLA1")}</li>
											<p>{t("CFLA11")}</p>
											<li>{t("CFLA2")}</li>
											<p>{t("CFLA22")}</p>
											<li>{t("CFLA3")}</li>
											<p>{t("CFLA33")}</p>
											<li>{t("CFLA4")}</li>
											<p>{t("CFLA44")}</p>
										</ol>
									</a>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="contentBlock noPadding">
				{
					isVertical
						? <div>
							<div style={{ borderBottom: `1px solid ${COLORS.darkGreen}`, padding: "5rem 0" }}>
								<p style={{ margin: 0, fontSize: "3rem" }}>{t("contactUs")}</p>
							</div>
							<Accordion items={getAccordionItems(t)} showArrow={false} titleStyle={{ borderBottom: `1px solid ${COLORS.darkGreen}`, marginBottom: "-1px", justifyContent: "center" }} />
						</div>
						: <VerticalAccordion items={getVerticalAccordionItems(t)} />
				}
			</div>
			<div className="contentBlock partners">
				<p>{t("rurePartners")}</p>
				<div className="logos">
					<StaticImage
						src="../images/partners/aima-logo.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Aima"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/arbor-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Arbor"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/altum-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Altum"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/Liepaja-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Liepaja"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/plutos-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Plutos"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/imre-logo.png"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Imre"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/baltic-lands-logo.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Baltic lands"
						placeholder="blurred"
						objectFit="contain"
					/>
					<StaticImage
						src="../images/partners/rigas-zile-logo.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt="Rigas zīle"
						placeholder="blurred"
						objectFit="contain"
					/>
					{isVertical ? null : <div />} {/* empty placeholder */}
				</div>
				<div className="contractTable">
					<table>
						<thead>
							<tr>
								<td
									style={{
										textAlign: "center",
										fontSize: isVertical ? "1.7rem" : "1.1rem",
										lineHeight: 1,
										fontWeight: "bold",
									}}
								>SIA Magnum Social & Medical Care</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{
									paddingLeft: "1rem",
									paddingRight: "1rem",
									textAlign: "center",
									fontSize: isVertical ? "1.2rem" : "0.8rem",
									lineHeight: 1.3,
								}}>
									{t("rureContract")}
								</td>
							</tr>
							<tr>
								<td style={{
									padding: 0,
								}}>
									<StaticImage
										src="../images/partners/NAP-ES.png"
										quality={95}
										formats={["auto", "webp", "avif"]}
										alt="NAP ES"
										placeholder="none"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Layout>
	);
}

const getVerticalAccordionItems = (t: TFunction) => {
	const isVertical = getIsVertical();

	return [
		{
			title: isVertical ? t("mobilePalliativeCare") : t("palliativeCareAtHome"),
			Content: (
				<div className="accordionContentContainer">
					<div>
						<div>
							{isVertical ? null : <p className="contactUsTop">{t("contactUs")}</p>}
							<h2><Trans i18nKey="careAtHome" components={{ break: <br /> }} /></h2>
						</div>
						<div>
							<p><b>{t("riga")}</b></p>
							<h2>{t("phoneNr")}<a href="tel:+3718884">8884</a> <br /><a href="mailto:info.riga@rure.lv">info.riga@rure.lv</a></h2>
						</div>
					</div>
					<div>
						<div>
							<p>{t("kurzemeDistrict")}</p>
							<p><b><Trans i18nKey="cities1" components={{ break: <br /> }} /></b></p>
							<h2>{t("phoneNr")}<a href="tel:+3718882">8882</a> <br /><a href="mailto:info.kurzeme@rure.lv">info.kurzeme@rure.lv</a></h2>
						</div>
						<div>
							<p>{t("latgaleDistrict")}</p>
							<p><b>{t("cities2")}</b></p>
							<h2>{t("phoneNr")}<a href="tel:+3718886">8886</a> <br /><a href="mailto:info.latgale@rure.lv">info.latgale@rure.lv</a></h2>
						</div>
					</div>
				</div>
			),
		},
		{
			title: t("healthCenterLiepaja"),
			Content: (
				<div className="accordionContentContainer">
					<div>
						{isVertical ? null : <p className="contactUsTop">{t("contactUs")}</p>}
						<div className="vclContainer">
							<h2><Trans i18nKey="healthCenter" components={{ break: isVertical ? <></> : <br /> }} /></h2>
							<div>
								<h2 style={{ marginBottom: "4rem" }}><a href="mailto:info@rure.lv">info@rure.lv</a></h2>
								<h2 style={{ marginBottom: "0.3rem" }}>{t("phoneNr")}<a href="tel:+37129396285">+371 293 962 85</a></h2>
								<p><Trans i18nKey="workTime" components={{ break: <></> }} /></p>
								<h2 style={{ marginTop: "5rem" }}><Trans i18nKey="address" components={{ break: <br /> }} /></h2>
							</div>
						</div>
					</div>
				</div>
			),
		},
		{
			title: t("medServicesAndRent"),
			Content: (
				<div className="accordionContentContainer">
					<div>
						<div>
							{isVertical ? null : <p className="contactUsTop">{t("contactUs")}</p>}
							<h2><Trans i18nKey="medicalServicesAndRent" components={{ conditionalBreak: isVertical ? <></> : <br />, break: <br /> }} /></h2>
						</div>
						<div>
							<p><b>{t("riga")}</b></p>
							<h2>{t("phoneNr")}<a href="tel:+3718884">8884</a> <br /><a href="mailto:info.riga@rure.lv">info.riga@rure.lv</a></h2>
						</div>
					</div>
					<div>
						<div>
							<p>{t("kurzemeDistrict")}</p>
							<p><b><Trans i18nKey="cities1" components={{ break: <br /> }} /></b></p>
							<h2>{t("phoneNr")}<a href="tel:+3718882">8882</a> <br /><a href="mailto:info.kurzeme@rure.lv">info.kurzeme@rure.lv</a></h2>
						</div>
						<div>
							<p>{t("latgaleDistrict")}</p>
							<p><b>{t("cities2")}</b></p>
							<h2>{t("phoneNr")}<a href="tel:+3718886">8886</a> <br /><a href="mailto:info.latgale@rure.lv">info.latgale@rure.lv</a></h2>
						</div>
					</div>
				</div>
			),
		},
		{
			title: t("aboutCoop"),
			Content: (
				<div className="accordionContentContainer">
					<div>
						{isVertical ? null : <p className="contactUsTop">{t("contactUs")}</p>}
						<div className="vclContainer">
							<h2><Trans i18nKey="cooperation" components={{ condBreak: isVertical ? <></> : <br />, break: <br /> }} /></h2>
							<div>
								<h2 style={{ marginBottom: "3rem" }}><Trans i18nKey="contactKD" components={{ break: <br /> }} /></h2>
								<a href="mailto:kristians@rure.lv"><h2>kristians@rure.lv</h2></a>
							</div>
						</div>
					</div>
				</div>
			),
		},
	];
};

const getAccordionItems = (t: TFunction) => {
	return getVerticalAccordionItems(t).map(item => ({
		Title: <p>{item.title}</p>,
		Content: item.Content,
	}))
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["kas-ir-rure", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
